header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100px;
    // background: rgba(#000, .2);
    background: linear-gradient(to bottom, #000 0%, #000 10%, transparent);
    // border-bottom: 1px solid #8d6d4e;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    @media screen and (max-width: 840px) {
        padding: 0 15px;
        height: 60px;
    }
    .side {
        flex: 1 1 1px;
        &.right {
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width: 570px) {
                position: absolute;
                bottom: -85vh;
                left: calc(50% - 68px);
                margin: auto;
            }
        }
    }
    .logo {
        flex: none;
        @media screen and (max-width: 840px) {
            width: 100px;
        }
        img {
            display: block;
            max-width: 100%;
        }
    }
    .logo-sc {
        img {
            display: block;
        }
    }
    .button {
        @media screen and (max-width: 840px) {
            height: 34px;
            font-size: 12px;
        }
    }
}