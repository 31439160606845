.homepage {
    flex: 1 1 auto;
    #fullpage {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }
    &__section {
        position: relative;
        &-inner {
            position: relative;
            flex: 1 1 auto;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            overflow: hidden;
            height: 100vh;
            @media screen and (max-width: 1024px) {
                // height: auto !important;
           }
            &:after,
            &:before {
                content: '';
                display: block;
                height: 200px;
                background: linear-gradient(to bottom, transparent 0%, #000 95%, #000 100%);
                position: absolute;
                left: 0;
                width: 100%;
                z-index: 2;
                @media screen and (max-width: 768px) {
                    height: 70px;
                }
            }
            &:after {
                bottom: 0;
            }
            &:before {
                background: linear-gradient(to top, transparent 0%, #000 95%, #000 100%);
                top: 0;
            }
        }
        .video-wrapper {
            min-width: 100vw;
            min-height: 100vh;
            flex: 1 1 auto;
            overflow: hidden;
            position: relative;
            z-index: 2;
            opacity: .1;
            &.active {
                transition: .3s ease;
                opacity: 1;
            }
            @media screen and (max-width: 1640px) {
                display: flex;
                justify-content: center;
            }
            @media screen and (max-width: 1024px) {
               //display: none;
            }
        }
        video {
            position: relative;
            min-width: 100%;
            flex: 1 1 auto;
            display: block;
            @media screen and (max-width: 1640px) {
                min-width: 1px;
                height: 100vh;
                flex: none;
            }
        }
        .homepage__section-bg {
            display: none;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            z-index: 2;
            @media screen and (max-width: 1024px) {
              // display: block;
            }
        }
        &_one {
            // background: url('../assets/images/bg1.jpg') center center no-repeat;
            &.playing {
                .overlay {
                    opacity: 0;
                    pointer-events: none;
                    .fire {
                        transition: .5s ease-in-out;
                        transform: translateY(50px)
                    }
                }
            }
            @keyframes pulse {
                0% {
                    background: rgba(#000, .8);
                }
                50% {
                    background: rgba(#000, .6);
                }
                100% {
                    background: rgba(#000, .8);
                }
            }
            .video-wrapper {
                @media screen and (max-width: 1024px) {
                    display: flex !important;
                    justify-content: center;
                    align-items: center;
                }
            }
            .overlay {
                background: rgba(#000, .8);
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                z-index: 5;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                transition: .5s ease-in-out;
                animation: pulse 3s ease infinite;
                @media screen and (max-width: 1640px) {
                    justify-content: center;
                }

                @media screen and (max-width: 400px) {
                    // background: none;
                    // animation: none;
                }
                img.over-img {
                    display: block;
                    position: absolute;
                    margin-left: -50vw;
                    left: 50%;
                    width: 100vw;
                    bottom: -3px;
                @media screen and (max-width: 1024px) {
                    display: none;
                }
                }
                .content {
                    flex: 1 1 auto;
                    position: relative;
                }
                // svg {
                //     position: absolute;
                //     fill: #fff;
                //     top: 50%;
                //     width: 100px;
                //     height: 100px;
                // }
                &.btn-hide {
                    .btn {
                        opacity: 0;
                        pointer-events: none;
                    }
                }
                .btn {
                    position: absolute;
                    left: 50%;
                    margin-left: -110px;
                    bottom: 380px;
                    z-index: 4;
                    width: 220px;
                    height: 54px;
                    cursor: pointer;
                    background: linear-gradient(to right, #ea7b1c, #a71117);
                    border-radius: 30px;
                    box-shadow: 0px 3px 0px 0px #a71117;
                    font-family: "Roboto Slab";
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    font-weight: 600;
                    padding: 0;
                    opacity: 1;
                    user-select: none;
                    transition: .2s ease;
                    &:hover {
                        opacity: 0.9;
                    }
                    &:active {
                        transform: translateY(3px);
                        box-shadow: 0px 0px 0px 0px #a71117;
                    }
                    svg {
                        width: 24px;
                        height: 32px;
                        display: block;
                        fill: #fff;
                        flex: none;
                        margin-right: 10px;
                    }
                }
            }
        }
        &_two {
            .text-overlay {
                .title {
                    bottom: 280px;
                    left: -60px;
                    position: relative;
                    img {
                        position: relative;
                    }
                    @media screen and (max-height: 900px) {
                        bottom: 230px;
                    }
                    @media screen and (max-height: 768px) {
                        bottom: 100px;
                        left: 0px;
                    }
                    @media screen and (max-width: 768px) {
                        bottom: 260px;
                    }
                    @media screen and (max-width: 640px) {
                        bottom: 100px;
                        img {
                            width: 150px;
                        }
                    }
                }
            }
        }
        &_four {
            .text-overlay {
                .title {
                    bottom: 390px;
                    left: 110px;

                    position: relative;
                    img {
                        position: relative;
                        right: -20px;
                    }
                    @media screen and (max-width: 1440px) {
                        bottom: 200px;
                        left: 50px;
                    }
                    @media screen and (max-height: 900px) {
                        bottom: 280px;
                    }
                    @media screen and (max-height: 768px) {
                        bottom: 255px;
                    }
                    @media screen and (max-width: 1024px) {
                        bottom: 400px;
                        img {
                            left: -200px;
                        }
                    }
                    @media only screen
                    and (min-width : 768px)
                    and (max-width : 1024px)
                    and (orientation : landscape) {
                        bottom: 130px;
                        left: 80px;
                    }
                    @media screen and (max-width: 768px) {
                        bottom: 230px;
                        img {
                            width: 350px;
                            left: -180px;
                        }
                    }
                    @media screen and (max-width: 640px) {
                        bottom: 140px;
                        left: 40px;

                        img {
                            width: 200px;
                            right: auto;
                            left: -80px;
                        }
                    }
                }
            }
            video {
                @media screen and (max-width: 768px) {
                    margin-right: -120px !important;
                }
            }
        }
        &_five {
            .text-overlay {
                .title {
                    bottom: 460px;
                    left: -340px;

                    position: relative;
                    img {
                        position: relative;
                    }
                    @media screen and (max-width: 1440px) {
                        bottom: 340px;
                        left: -300px;
                    }

                    @media screen and (max-width: 1366px) {
                        bottom: 500px;
                        left: -350px;
                    }
                    @media screen and (max-width: 1024px) {
                        bottom: -94px;
                        left: -90px;
                        img {
                            left: -200px;
                        }
                    }
                    @media screen and (max-width: 768px) {
                        bottom: -45px;
                        left: -250px;
                        img {
                            left: 0
                        }
                    }
                    @media only screen
                    and (min-width : 768px)
                    and (max-width : 1024px)
                    and (orientation : landscape) {
                        bottom: -95px;
                        left: 0px;
                    }
                    @media screen and (max-width: 640px) {
                        bottom: -100px;
                        left: -156px;
                        img {
                            width: 200px;
                        }
                    }

                    @media screen and (max-width: 410px) {
                        left: -100px;
                    }
                }
            }
        }
        &_three {
            .text-overlay {
                .title {
                    bottom: 30px;
                    left: 590px;
                    position: relative;
                    img {
                        position: relative;
                        right: 60px;
                    }
                    @media screen and (max-height: 1440px) {
                        //bottom: 300px;
                    }

                    @media screen and (max-width: 1366px) {
                        bottom: -80px;
                        left: 530px;

                    }


                    @media screen and (max-width: 1024px) {
                        bottom: 300px;
                        img {
                            right: 100px;
                        }
                    }

                    @media screen and (max-height: 900px) {
                        bottom: 300px;
                    }
                    @media screen and (max-height: 900px) {
                        bottom: 300px;
                    }
                    @media screen and (max-height: 768px) {
                        bottom: 375px;
                    }
                    @media screen and (max-width: 768px) {
                        bottom: 572px;
                    }
                    @media only screen
                    and (min-width : 768px)
                    and (max-width : 1024px)
                    and (orientation : landscape) {
                        bottom: -70px;
                        left: 380px;
                    }

                    @media only screen
                    and (min-width : 768px)
                    and (max-width : 1024px)
                    and (orientation : portrait) {
                        bottom: -70px;
                        left: 450px;
                    }

                    @media screen and (max-width: 640px) {
                        bottom: -130px;
                        left: -110px;
                        img {
                            width: 200px;
                            right: 30px;
                        }
                    }
                    @media screen and (max-width: 410px) {
                        left: -60px;
                        bottom: -50px;
                    }
                }
            }
        }
        &_six {
            .text-overlay {
                .title {
                    position: relative;
                    bottom: 420px;
                    left: -317px;

                    @media screen and (max-width: 1440px) {
                        bottom: 200px;
                        left: -290px;
                    }

                    @media screen and (max-width: 1366px) {
                        bottom: 400px;
                        left: -270px;
                    }
                    @media screen and (max-width: 768px) {
                        bottom: -10px;
                        left: -190px;
                        img {
                            width: 300px;
                        }
                    }
                    @media only screen
                    and (min-width : 768px)
                    and (max-width : 1024px)
                    and (orientation : landscape) {
                        bottom: 250px;
                        left: -240px;
                    }
                    @media screen and (max-width: 640px) {
                        bottom: -70px;
                        left: 10px;
                        img {
                            width: 200px;
                        }
                    }

                    @media screen and (max-width: 410px) {
                        bottom: -100px;
                    }
                }
            }
        }
        &_seven {
            .text-overlay {
                .title {
                    position: relative;
                    bottom: calc(100vh - 60vh);
                    left: 470px;
                    img {
                        position: relative;
                        right: 150px;
                    }

                    @media screen and (max-width: 1366px) {
                        bottom: calc(100vh - 50vh);
                        left: 560px;
                        img {
                            right: 250px;
                        }
                    }
                    @media screen and (max-width: 1024px) {
                        // bottom: -10px;
                        bottom: calc(100vh - 45vh);
                        left: 200px;
                        img {
                            right: -160px;
                        }
                    }
                    @media screen and (max-width: 768px) {
                        bottom: calc(100vh - 50vh);
                        left: 100px;
                        img {
                            right: -60px;
                        }
                    }

                    @media only screen
                    and (min-width : 768px)
                    and (max-width : 1024px)
                    and (orientation : landscape) {
                        bottom: calc(100vh - 65vh);
                        left: 100px;
                    }

                    @media screen and (max-width: 640px) {
                        bottom: 280px;
                        left: 107px;
                        img {
                            right: 0;
                        }
                    }
                    @media screen and (max-width: 410px) {
                        bottom: 260px;
                        left: 60px;
                    }
                    // @media screen and (max-width: 640px) {
                    //     bottom: -50px;
                    //     img {
                    //         width: 200px;
                    //     }
                    // }

                    img {
                        @media screen and (max-width: 410px) {
                            width: 200px;
                        }
                    }
                }
            }
        }
        .text-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            z-index: 3;
            padding-bottom: 150px;
            .wrp {
                max-width: 1100px;
                width: 100%;
                margin: 0 auto;
            }
            .title {
                // width: 850px;
                // height: 111px;
                // background: url('../assets/images/bg6.png') center center no-repeat;
                // color: #000;
                // font-family: "Roboto Slab";
                // font-size: 32px;
                // text-transform: uppercase;
                // font-weight: 700;
                // padding: 0 30px;
                // line-height: 1.2em;
                // display: flex;
                // align-items: center;
                img {
                    display: block;
                    margin: 0 auto;

                    @media only screen
                    and (min-width : 768px)
                    and (max-width : 1024px)
                    and (orientation : landscape) {
                        width: 250px;
                    }
                }
            }
        }
        .mouse {
            position: absolute;
            bottom: 50px;
            left: 50%;
            margin-left: -80px;
            width: 160px;
            z-index: 10;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            @media screen and (max-width: 1366px) {
                bottom: 20px;
            }
            @media screen and (max-width: 640px) {
                bottom: 10px;
            }
            .icon {
                margin-bottom: 15px;
                @media screen and (max-width: 640px) {
                    margin-bottom: 5px;
                }
                img {
                    display: block;
                }
            }
            span {
                text-align: center;
                font-size: 14px;
                text-transform: uppercase;
                opacity: .5;
                @media screen and (max-width: 1024px) {
                    font-size: 12px
                }
            }
        }
        .grad{
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: url('../assets/images/bgg.png') center center no-repeat;
            z-index: 2;
        }
    }
}

#fp-nav {
    ul {
        li {
            a {
                span {
                    background: #fff;
                }
            }
        }
    }
}

$fireColor: rgb(255,80,0);
$fireColorT: rgba(255,80,0,0);
$dur: 2s;
$blur: 0;
$fireRad: 2em;
$parts: 50;
$partSize: 10em;

.fire {
	font-size: 24px;
	filter: blur($blur);
	margin: 0 auto;
    position: relative;
    bottom: 320px;
	width: 200px;
    height: 12em;
    position: relative;
    @media screen and (max-width: 1080px) {
        bottom: 200px;
    }
    .button {
        position: absolute;
        width: 100%;
        bottom: 5px;
        background: #c84f02;
        background: linear-gradient(to bottom, #ff8d00, #993000);
        border-radius: 30px;
        box-shadow: 0px 0px 20px 0px rgba($fireColor, .5);
        color: #9c3200;
        text-shadow: 1px 1px 1px #f48300;
        font-weight: 700;
        font-size: 18px;
        padding: 0;
        white-space: nowrap;
    }
    .wrapper {
        width: 140px;
        height: 140px;
        margin: 0 auto;
        border-radius: 50%;
        background: #b25244;
        background: linear-gradient(#a71117, #5d0004);
        position: relative;
        cursor: pointer;
        padding: 20px;
        z-index: 2;
        bottom: -90px;
        // box-shadow:
        // inset 0 2px 3px rgba(255,255,255,0.13),
        // 0 5px 8px rgba(0,0,0,0.5),
        // 0 10px 10px 4px rgba(0,0,0,0.3);
   }

   .wrapper:after {
        content: "";
        position: absolute;
        left: -20px;
        right: -20px;
        top: -20px;
        bottom: -20px;
        z-index: -2;
        border-radius: inherit;
        box-shadow:
        inset 0 1px 0 rgba(255,255,255,0.1),
        0 1px 2px rgba(0,0,0,0.3),
        0 0 10px rgba(0,0,0,0.15);

   }

   .wrapper:before {
        content: "";
        position: absolute;
        left: -10px;
        right: -10px;
        top: -10px;
        bottom: -10px;
        z-index: -1;
        border-radius: inherit;
        box-shadow: inset 0 10px 10px rgba(0,0,0,0.13);
        -webkit-filter:blur(1px);
        filter: blur(1px);
   }

   .inner {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: linear-gradient(#5d0004, #a71117);
        display: block;
        box-shadow:
        0 -2px 5px rgba(255,255,255,0.05),
        0 2px 5px rgba(255,255,255,0.1);
        span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;
            font-weight: 900;
            font-size: 18px;
            line-height: 1.5em;
            text-align: center;
        }
   }

//    .inner:after {
//         position: absolute;
//         width: 50px;
//         height: 50px;
//         background: white;
//         background: linear-gradient(#eaeceb, #8d8d8d);
//         left: 50%;
//         top: 50%;
//         content: "";
//         border-radius: inherit;
//         margin: -25px 0 0 -25px;
//         box-shadow: 0 3px 5px rgba(0,0,0,0.3);
//    }

//    .inner:before {
//         position: absolute;
//         content: "";
//         width: 40px;
//         height: 40px;
//         left: 50%;
//         top: 50%;
//         border-radius: inherit;
//         background: inherit;
//         margin: -20px 0 0 -20px;
//         z-index: 2;
//         box-shadow: inset 0 3px 5px rgba(0,0,0,0.3), 0 1px 2px rgba(255,255,255,1);
//    }

//    .inner span {
//         display: block;
//         width: 20px;
//         height: 20px;
//         background: #8f3327;
//         position: absolute;
//         left: 50%;
//         margin-left: -10px;
//         top: 25px;
//         z-index: 3;
//         position: relative;
//    }

//    .inner span:before {
//         content: "";
//         position: absolute;
//         width: 6px;
//         height: 25px;
//         background: linear-gradient(#fff, #cbcbcd);
//         border-radius: 10px;
//         box-shadow: 0 3px 5px rgba(0,0,0,0.3);
//         left: 50%;
//         margin-left: -3px;
//    }
}
.particle {
    pointer-events: none;
	animation: rise $dur ease-in infinite;
	background-image: radial-gradient($fireColor 20%,$fireColorT 70%);
	border-radius: 50%;
	mix-blend-mode: screen;
	opacity: 0;
	position: absolute;
	bottom: 0;
	width: $partSize;
	height: $partSize;
	// spread particles out in time and x-position to get desired effect
	@for $p from 1 through $parts {
		&:nth-of-type(#{$p}) {
            animation-delay: $dur * random();
			left: calc((100% - #{$partSize}) * #{($p - 1)/$parts});
		}
	}
}
@keyframes rise {
	from {
		opacity: 0;
		transform: translateY(0) scale(1);
	}
	25% {
		opacity: .9;
	}
	to {
		opacity: 0;
		transform: translateY(-10em) scale(0);
	}
}

.opened-modal {
    .modal {
        display: flex;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 101;
    background: #191819;
    text-align: center;
    display: none;
    flex-direction: column;
    &-wrapper {
        flex: 1 1 auto;
        overflow-y: auto;
        height: 100vh;
    }
    &-top {
        background: url('../assets/images/bg2.jpg') center top repeat;
        background-size: cover;
        position: relative;
        z-index: 2;
        padding: 30px 0;
        &:after {
            content: '';
            display: block;
            background: url('../assets/images/bg8.png') center top repeat;
            background-size: cover;
            height: 50px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
    .content {
        max-width: 900px;
    }
    &-close {
        cursor: pointer;
        width: 32px;
        height: 32px;
        position: absolute;
        right: 30px;
        top: 30px;
        z-index: 3;
        svg {
            width: 32px;
            height: 32px;
            fill: #fff;
        }
    }
    .logo {
        margin-bottom: 20px;
        img {
            margin: 0 auto;
            max-width: 100%;
            display: block;
        }
    }
    .img {

        img {
            margin: 0 auto;
            display: block;
            max-width: 100%;
        }
    }
    .wrap {
        text-align: center;
        padding-top: 40px;
        img {
            display: block;
            max-width: 100%;
        }
        &-title {
            color: #fff;
            text-transform: uppercase;
            font-size: 28px;
            margin-bottom: 40px;
        }
        &-items {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: -10px;
            .item {
                flex: none;
                width: 268px;
                height: 78px;
                margin: 10px;
                background: url('../assets/images/bg9.png') center center repeat;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    display: block;
                    max-width: 100%;
                }
            }
        }
    }
}

.mute {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    &.muted {
        svg {
            &:last-child {
                display: block !important;
            }
            &:first-child {
                display: none;
            }
        }
    }
    svg {
        display: block;
        fill: #fff;
        width: 25px;
        height: 25px;
        &:last-child {
            display: none;
        }
    }
}

// .preloader {
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 99999;
//     width: 100%;
//     height: 100%;
//     background: #000;
// }

.pace {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background: #000;
    user-select: none;
    opacity: 1;
    transition: .5s ease-in-out 1s;
    &-inactive {
        opacity: 0;
        pointer-events: none;
        transform: scale(1.5);
    }
    &-progress {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 99999;
        right: 100%;
        width: 100%;
        height: 100%;
        transform: translate3d(0, 0, 0) !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @keyframes rotating {
            100% {
                transform: rotate(360deg)
            }
        }
        &:before {
            content: '';
            display: block;
            background: url('../assets/images/el.png') center center no-repeat;
            width: 128px;
            height: 125px;
            animation: rotating 2s linear infinite;
        }
        &:after {
            display: block;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            content: attr(data-progress-text);
            text-align: center;
            color: #fff;
            font-size: 28px;
            margin-top: 50px;
            font-family: "Roboto Slab";
        }
    }
    &-inner {

    }
}

.hide-on-mobile {
    @media screen and (max-width: 1025px) {
        display: none !important;
    }
}

.show-on-mobile {
    display: none !important;

    @media screen and (max-width: 570px) {
        display: block !important;
    }
}

.show-on-tablets  {
    display: none !important;

    @media screen and (max-width: 1025px) {
        display: block !important;
    }

    @media screen and (max-width: 1025px) {
        display: block !important;
    }

    // @media only screen
    // and (min-width : 1366px)
    // and (max-width : 1024px)
    // and (orientation : landscape) {
    //     bottom: calc(100vh - 65vh);
    //     left: 100px;
    // }

    @media screen and (max-width: 570px) {
        display: none !important;
    }
}

.rotate-block {
    display: none;
    position: fixed;
    z-index: 10000000000000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100vw;
    min-height: 100vh;
    //min-height: 100vw;
    margin: auto;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.8);

    img {
        display: block;
        margin: auto;
    }
}

.video-wrapper {
    opacity: 1;

    @media screen and (max-width: 1025px) {
        opacity: 1 !important;
    }
}

.mouse {
    @media screen and (max-width: 1025px) {
        display: none !important;
    }
}

.mobile-video {
    bottom: 35px;
    min-height: 100vh;
    min-width: 100vw !important;
}

// @media screen and (min-height: 600px) and (max-height: 800px) {
//     .mobile-video{
//     }
// }

  @media screen and (min-width: 320px) and (max-width: 1025px) and (orientation: landscape) {

    .rotate-block {
        display: flex;
    }
    html{
        transform: rotate(-90deg);
        transform-origin: left top;
        width: 100vh;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
    }

    .homepage__section .mobile-video ,
    .homepage__section-inner,
    .video-wrapper,
    #fullpage,
    #firstOverlay {
        min-height: 100vw !important;
    }

    .video-wrapper {
        .mobile-video {
            min-width: 100vh;
            min-height: 100vw;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
    }

    .homepage__section_one .overlay .btn {
        z-index: 5;
    }

    #firstOverlay {
        z-index: 1;
    }

    .mobile-video {
        z-index: -1;
    }

    .mob-header {
        z-index: 111000;
    }

    .mute {
        z-index: 101010;
    }

    .modalWrapper {
        min-height: 100vw;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 1024px) {
    .mobile-video {
        object-fit: cover;
    }
  }

//   .gil {
//     display: none;
//     position: absolute;
//     //z-index: 10;
//     top: 0;
//     right: 0;
//     width: 100%;
//     height: 100%;
//     background: linear-gradient(to bottom, #000 0%, rgba(0,0,0,0) 50%);
//     //background: linear-gradient(to bottom, rgba(0,0,0,1) 13%, rgba(0,0,0,0.66) 25%, rgba(0,0,0,0) 100%);

//     @media screen and (max-width: 1025px) {
//         display: block;
//     }
// }